$secondary_color: #0e61ff;
$black_color: #000;
$white_color: #ffffff;
$gray_color: #63717d;
$gray_color2: #d2d2d25c;
$dark_gray_color: #121213;
$light_gray: #f8f8f8;
$bg_gray: #eeeeee;
$medium_gray: #d7dbdf;
$medium2_gray: #c4c4c4;
$medium3_gray: #999999;
$transparent: transparent;
$red_color: #f00;
$light_red_color: #ff5f00bd;
$green_color: #558b2f;
$light_green_color: #558b2f99;
$fs_body: 14px;
$fs_xs_body:12px;

body>iframe {
  display: none;
}

.required {
  color: $red_color;
}

@keyframes menu-in-left {
  0% {
    transform: translate(-150%, -100%) translate3d(0, 0, 0);
  }

  100% {
    transform: translate(-50%, -100%) translate3d(0, 0, 0);
  }
}

@keyframes menu-in-right {
  0% {
    transform: translate(100%, 0%) translate3d(0, 0, 0);
  }

  100% {
    transform: translate(-50%, 0%) translate3d(0, 0, 0);
  }
}

.tree-item-box {
  border: 1px solid #d7dbdf;
  margin-bottom: 10px;
  margin-right: 10px;

  .material-icons {
    width: 15px !important;
    padding: 0 2px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-color: $bg_gray;
  font-family: Inter, Arial, sans-serif;
}

html {
  scrollbar-color: $black_color $medium3_gray;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: $medium3_gray;
}

::-webkit-scrollbar-thumb {
  background: $black_color;
}

.no-scroll {
  overflow: hidden;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
}

a {
  outline: 0;
  text-decoration: none !important;
  -webkit-transition: all ease 0.35s;
  -moz-transition: all ease 0.35s;
  transition: all ease 0.35s;
  color: $secondary_color;
}

.fs-body {
  font-size: $fs_body;
}

.fs_xs_body {
  font-size: $fs_xs_body;
}

.text-success {
  color: $green_color !important;
}

.bg-green {
  background-color: $light_green_color !important;
  border-radius: 5px;
}

.bg-red {
  background-color: $light_red_color !important;
  border-radius: 5px;
}

.text-info {
  color: $secondary_color !important;
}

.text-brand {
  color: $dark_gray_color;
}

.text-dark-brand {
  color: $dark_gray_color;
}

.text-link {
  font-weight: 400;
  color: $secondary_color;
  text-decoration: none;
  margin-bottom: 0px;
  cursor: pointer;
}

.h1,
h1 {
  font-weight: bold;
  font-size: 2.2rem;
}

.h2,
h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

.h3,
h3 {
  font-size: 1.25rem;
  font-weight: 600;
}

.h4,
h4 {
  font-size: 1.1rem;
  font-weight: 500;
}



.row-sm,
.form-row {
  margin-right: -8px;
  margin-left: -8px;

  >*,
  >[class*=col-] {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.page-wrapper {
  padding-left: 0 !important; // for table overflow fixes for large tables
  padding-right: 0 !important; // for table overflow fixes for large tables
  min-height: calc(100vh - 60px);
  display: flex;
  // gap: 25px; // for table overflow fixes for large tables

  .page-content {
    flex: 1;
    overflow: auto; // for table overflow fixes for large tables
    padding: 0 25px; // for table overflow fixes for large tables
  }

  #sidenav {
    width: 280px;
    height: calc(100vh - 60px);
    padding: 60px .5rem 2.1rem .5rem;
    transition: margin 0.5s ease;
    margin-left: -280px;
    background: $black_color;
    color: $white_color;
    position: fixed;
    z-index: 9;
    top: 60px;

    .sidebar-container {
      display: flex;
      // padding: 40px 0 0;
      width: 100%;
      flex-flow: column;
      gap: 10px;

      .sidebar-nav {
        width: 100%;

        li {
          width: 100%;

          // .MuiDivider-root {
          //   background: $dark_gray_color
          // }
          .MuiCollapse-root {
            border: 1px solid $dark_gray_color;

            .MuiList-root {
              padding: 1px;
            }
          }

          .MuiListItemIcon-root {
            min-width: 25px;
          }

          a {
            color: $white_color;
            font-size: $fs_body;

            &.active {
              color: $secondary_color;
              background: $dark_gray_color;
            }
          }

          &.active {
            a {
              color: $secondary_color;
              background: $white_color;
            }
          }
        }
      }
    }

    .toggle-btns {
      position: absolute;
      top: 0;
      right: -20px;
      font-size: 1.8rem;
      // transform: translateY(-50%);
      border: none;
      cursor: pointer;
      z-index: 9;
      display: flex;
      flex-flow: row-reverse;
      justify-content: flex-end;
      gap: 7px;
      padding: 10px 0;

      button {
        background: $dark_gray_color;
        width: 40px;
        height: 40px;
        outline: none;
        color: $white_color;
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 0 10px;
        box-shadow: 0 0 10px $black_color;
        transition: all 0.3s ease;
        border-radius: 100%;
        border: none;
        cursor: pointer;

        &.sidebar-toggle {
          &:not(.toggled) {
            // transform: translateY(-50%);
            font-size: 1.6rem;
            height: 50px;
            width: 25px;
            border-top-left-radius: 0%;
            border-bottom-left-radius: 0%;
            border-top-right-radius: 100%;
            border-bottom-right-radius: 100%;
          }

          &.toggled {
            border-radius: 100%;
            width: 40px;
            height: 40px;
            padding: 0 0 0 10px;
          }
        }

        &.pin-toggle {
          opacity: 0;
        }

        &.pin-toggle.pinned {
          background: $white_color;
          color: $black_color;
        }

        &:disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }

    &.expanded {
      margin-left: 0;
      box-shadow: 0 0 10px $black_color;
      overflow: auto;
      padding-top: 0;

      .toggle-btns {
        right: 10px;
        position: sticky;
        justify-content: flex-start;
        top: 0;
        background: rgb(0, 0, 0);
        background: linear-gradient(180deg, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
      }

      .pin-toggle {
        opacity: 1 !important;
      }
    }

    &.sticky {

      // margin-left: -25px !important;
      // box-shadow: 0 0 10px $black_color !important;
      // position: relative !important;
      // top: 0;
      // height: inherit;
      &+.page-content {
        margin-left: 280px;
      }

      .sidebar-toggle {
        right: 0;
      }

      .toggle-btns+div {
        overflow: auto;
      }
    }
  }
}

header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;

  .navbar {
    background: $dark_gray_color !important;
    color: $white_color;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    text-transform: uppercase;
    font-weight: 500;
    justify-content: space-between;
    min-height: 60px;

    .logo-img {
      max-height: 50px;
    }

    a {
      color: $white_color;
      text-decoration: none;
    }

    .btn-link {
      color: $white_color;
      text-decoration: none;
    }

    .btn {
      align-items: center;
      display: flex;
      height: 100%;
    }

    .main-menu-container {
      display: flex;
      gap: 50px;
      align-items: center;
      justify-content: center;
    }
  }

  .navbar-brand {
    width: 248px;
    max-width: 30%;
  }

  .breadcrumbs {
    flex: 1;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      gap: 5px;
      max-width: calc(50vw - 280px);
      white-space: nowrap;
      overflow: hidden;
      position: relative;

      li {
        display: flex;
        align-items: center;

        a {
          // pointer-events: none;
          opacity: 0.5;

          &:hover {
            opacity: 0.8;
          }
        }

        &:last-child {
          pointer-events: none;

          a {
            opacity: 1;
          }
        }

        + {
          li {
            &::before {
              content: "/";
              display: inline-block;
              opacity: 0.5;
              color: $white_color;
              margin-right: 5px;
            }
          }
        }
      }

      &:after {
        display: block;
        content: " ";
        position: absolute;
        height: 100%;
        width: 25px;
        right: 0;
        background: linear-gradient(270deg,
            $dark_gray_color 0%,
            rgba(0, 0, 0, 0) 100%);
      }
    }
  }

  #profileBox {
    display: flex;
    gap: 3px;
    float: right !important;

    .profile-initials {
      width: 36px;
      height: 36px;
      line-height: 36px;
      border: 1px solid $white_color;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: 500;
      font-size: 1.2em;
    }
  }
}

#mobile-menu-box {
  position: relative;
  width: 100%;
  height: 0;
  z-index: 11;

  #mobileMenu {
    position: fixed;
    top: -50%;
    left: -50%;
    height: 200%;
    width: 200%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $transparent;
    visibility: hidden;
    z-index: 2;
    margin-top: 50px;

    //Rotate the menu to setup for the diagonal slice
    transform: rotate(-15deg) translate3d(0, 0, 0);

    //Create two rectangles on the top and bottom half of the menu
    &::before,
    &::after {
      display: block;
      content: "";
      position: absolute;
      left: 50%;
      width: 100%;
      height: 80%;
      background-color: $dark_gray_color;
      transform-origin: center center;
      transition: all 500ms ease;
      will-change: transform;
      z-index: -1;
    }

    &::before {
      //Position and transition for animation Open -> Closed
      top: 51%;
      transform: translate(100%, -100%) translate3d(0, 0, 0);
    }

    &::after {
      //Position and transition for animation Open -> Closed
      top: 49%;
      transform: translate(-150%, 0%) translate3d(0, 0, 0);
      transition-delay: 100ms;
    }

    .navbar-nav {
      position: relative;
      display: inline-block;
      padding: 0px;
      list-style: none;
      max-height: calc(100vh - 130px);
      overflow: auto;
      text-align: center;
      //Rotate nav oposite direction to counter the full menu rotation
      transform: rotate(15deg);
      width: 100vw;
      max-width: 600px;
      opacity: 0;
      transition: opacity 0.3s ease;
      transition-delay: 0s;

      .nav-item {
        margin-bottom: 10px;
        opacity: 0;
        transform: translateX(-15px) translate3d(0, 0, 0);
        transition: all 100ms ease 100ms;
        user-select: none;

        a {
          color: $white_color;
          font-size: 40px;
          font-weight: 700;
          letter-spacing: 2px;
          text-decoration: none;
          text-transform: uppercase;
          cursor: pointer;

          &.active {
            color: $secondary_color;
            pointer-events: none;
          }
        }
      }
    }

    &.open {
      visibility: visible;

      .navbar-nav {
        opacity: 1;
        transition: opacity 0.3s ease;
        transition-delay: 0.4s;
      }

      &::before {
        //Opening animation
        animation: menu-in-left 700ms ease;

        //Opened transform and transition
        transform: translate(-50%, -100%) translate3d(0, 0, 0);
        transition-duration: 0ms;
      }

      &::after {
        //Opening animation
        animation: menu-in-right 700ms ease 100ms;

        //Opened transform and transition
        transform: translate(-50%, 0%) translate3d(0, 0, 0);
        transition-duration: 0ms;
      }

      .nav-item {
        opacity: 1;
        transform: translateX(0px) translate3d(0, 0, 0);
        transition: all 250ms ease;
        transition-delay: 400ms;

        //Add incremental delay for menu items
        @for $i from 0 through 20 {
          &:nth-child(#{$i}n) {
            transition-delay: #{250 + ($i * 100)}ms;
          }
        }

        a {
          transition: all 250ms ease;
        }
      }
    }
  }

  .mobile-btn {
    position: fixed;
    //top:-100%;
    top: 5px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 3;
    //opacity: 0;
    transition: all 250ms ease;
    //transition-delay: 700ms;

    .material-icons {
      font-size: 45px;
      transition: all 250ms ease;
    }
  }

  &.open {
    .mobile-btn {
      opacity: 1;
      display: flex;
      top: 15px;

      .material-icons {
        font-size: 70px;
      }
    }
  }
}

.mobile-btn {
  background: transparent;
  color: $white_color;
  border: 0;
  cursor: pointer;
  display: flex;
  outline: 0 !important;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  top: 5px;

  .material-icons {
    font-size: 40px;
  }
}

div[class^="container-"] {
  &:not(.login-page) {
    background: $white_color;
  }
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 9vw;
  }

  .h1 {
    font-size: 9vw;
  }

  .h2 {
    font-size: 8vw;
  }

  h2 {
    font-size: 8vw;
  }

  .h3 {
    font-size: 6vw;
  }

  h3 {
    font-size: 6vw;
  }
}


.modal-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

/** bootstrape customization **/
.app-container {
  position: relative;

  .text-primary {
    color: $secondary_color !important;
  }
}

.readonly-form {

  // pointer-events: none;
  // user-select: auto;
  .MuiInputBase-root {
    pointer-events: none !important;
    user-select: text;
  }

  input,
  select,
  textarea {
    pointer-events: none;
    user-select: text;
  }
}

button {
  justify-content: center;

  span {
    &:first-child {
      flex: 1;
    }
  }

  &.MuiButton-sizeSmall {
    margin-left: -6px;
  }
}

.card {
  border-radius: 0;
  border: none;
}

// .MuiDataGrid-columnSeparator {
//   display: none !important;
// }

.MuiToolbar-root {
  p {
    margin: 0;
  }
}

.MuiDataGrid-root {
  background-color: $white_color;
  box-shadow: 0 0 4px $medium_gray !important;
  border-radius: 0 !important;
  color: $black_color !important;
}

.MuiDataGrid-cell,
.MuiDataGrid-columnHeader,
.MuiDataGrid-columnHeaderDraggableContainer {

  &:focus,
  &:active {
    outline: none !important;
  }
}

.MuiDataGrid-columnHeaders,
.MuiDataGrid-footerContainer {
  background: $light_gray;
}

.MuiFormControl-root {
  background-color: $white_color;

  &.selectField {
    margin-top: 6px;
  }
}

.form-group {
  margin-bottom: 0.5rem;
}

.MuiDataGrid-columnHeader {
  .MuiDataGrid-columnHeaderDraggableContainer {
    flex-direction: row-reverse;
    gap: 10px;

    .MuiDataGrid-menuIcon {
      width: 30px;
      visibility: visible;
    }
  }
}

.MuiInputLabel-root {
  top: -3px;
}

/* Login Page */
.login-page {
  min-height: 100vh;
  justify-content: center;
}

/* headerTitleRow */
.headerTitleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;

  >div {
    // width: calc(100vw - 300px);
    overflow: hidden;

    h1 {
      text-overflow: ellipsis;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  h1 {
    margin-bottom: 0;
    font-size: 32px;
  }

  >h1+span {
    white-space: nowrap;
  }

  button {
    line-height: 1 !important;
  }
}

.formButtonsRow {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $medium_gray;
  margin-top: 25px;
  gap: 5px;

  .MuiButton-root {
    padding-left: 25px !important;
    padding-right: 25px !important;
    min-width: 120px;
  }
}

/* loader */
.loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.overlay {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    min-height: calc(100vh - 65px);
    z-index: 1;

    &::after {
      content: " ";
      display: block;
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      background: $light_gray;
      opacity: 0.5;
    }

    &.block-loader {
      min-height: 100%;

      &::after {
        position: absolute;
      }
    }
  }
}

/* add - remove inputs */
.add-remove-box {
  column-gap: 8px;
  // display: flex;
  display: flex;
  align-items: flex-start;

  >button {
    margin-top: 12.5px;
  }

  &.separator {
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
  }
}

/** Animations **/
//Opening animations
@keyframes menu-in-left {
  0% {
    transform: translate(-150%, -100%) translate3d(0, 0, 0);
  }

  100% {
    transform: translate(-50%, -100%) translate3d(0, 0, 0);
  }
}

@keyframes menu-in-right {
  0% {
    transform: translate(100%, 0%) translate3d(0, 0, 0);
  }

  100% {
    transform: translate(-50%, 0%) translate3d(0, 0, 0);
  }
}

#dmn-editor-container {
  height: calc(100vh - 250px);
  min-height: 400px;
}

/** Backward compatiability for flex support in Safari 10X **/
.no-flexbox-gap {
  button {
    vertical-align: middle !important;
    display: table-cell !important;
  }

  .page-wrapper {
    #sidenav .toggle-btns {
      button {
        padding: 0 3px !important;
      }

      button+button {
        margin-right: 10px;
      }
    }

    .headerTitleRow button,
    .card-body button {
      line-height: 24px !important;

      .material-icons {
        float: left;
      }
    }

    .MuiTablePagination-actions {
      display: flex !important;
    }

    .sidebar.expanded {
      margin-right: 25px;
    }
  }
}

/** ends **/

/* custom radio */
.custom-form-elements {
  input:where([type="checkbox"], [type="radio"]) {
    -webkit-appearance: none;
    appearance: none;
    width: 22px;
    height: 22px;
    margin: calc(0.75em - 11px) 0.25rem 0 0;
    vertical-align: top;
    border: 2px solid $medium_gray;
    border-radius: 4px;
    background: $white_color no-repeat center center;
  }

  label {
    display: inline-block;
    padding: 10px 15px;
    border: 1px solid $black_color;
  }

  &.hrz-list-items {
    display: flex;
    gap: 10px;
    row-gap: 10px;
  }

  .custom-radio-checkbox {
    position: relative;
    display: inline-block;

    input[type="radio"],
    input[type="checkbox"] {
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      cursor: pointer;
    }

    label {
      border: 1px solid $medium_gray;
    }

    &:hover {
      label {
        //border-color: $black_color;
        background: $light_gray;
      }
    }

    input:where([type="checkbox"], [type="radio"]):where( :active:not(:disabled),
      :checked)+label {
      border-color: $secondary_color;
      color: $secondary_color;
    }
  }
}

// stand alone checkbox
.style-radio-checkbox {
  display: flex;
  align-items: center;

  label {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    cursor: pointer;
  }

  input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: $white_color;
    /* Not removed via appearance */
    margin: 0;
    cursor: pointer;
    font: inherit;
    color: currentColor;
    width: 20px;
    height: 20px;
    border: 1px solid currentColor;
    border-radius: 0;
    display: grid;
    place-content: center;

    &::before {
      content: "";
      width: 7px;
      height: 14px;
      translate: -4px -6px;
      border-bottom: 2px solid currentColor;
      border-right: 2px solid currentColor;
      transform: scale(0);
      transform-origin: bottom left;
      transition: 120ms transform ease-in-out;
    }

    &:checked::before {
      transform: scale(1) rotate(45deg);
    }

    &:focus {
      outline: none;
      outline-offset: max(2px, 0.15em);
    }

    &:disabled {
      color: $light_gray;
      cursor: not-allowed;
    }
  }
}

/* tables */
.table-container {
  // min-height: 500px;
  width: "100%"
}

.select-table-header:has(.row)+.advanced-table .table-responsive {
  max-height: calc(100vh - 290px);
}

.advanced-table {
  min-width: 100%;
  width: auto;
  margin-bottom: 15px;

  .disabled,
  .MuiStack-root.disabled {
    pointer-events: none;
    opacity: 0.5 !important;
  }

  .table-responsive {
    border: 1px solid $medium_gray;
    // border-width: 1px 0 0 0;
    max-height: calc(100vh - 230px);
    min-height: 400px;
  }

  table {
    // margin: -1px -1px 0 -1px;
    margin-bottom: 0;
    background: $white_color;
    color: $black_color;
    // width: calc(100% + 2px);

    .col-action,
    #col-action {
      position: sticky;
      right: -1px;
      border: 1px solid $medium_gray;
      background-color: $light_gray;
      border-left: 0;
      z-index: 2;
      min-width: 100px;
      box-shadow: inset 1px 0 0 $medium_gray;

      &:before {
        position: absolute;
        background: white;
        height: 100%;
        width: 1px;
        content: '';
        display: block;
        left: 1px;
        top: 0;
      }
    }

    .col-action {
      background-color: $white_color;
      z-index: 1;
    }

    thead {
      background: $light_gray;
      position: sticky;
      top: -1px;
      border-bottom: 1px solid $medium_gray;
      z-index: 3;

      th {
        position: relative;
        border-top: 0;
        border: 1px solid $medium_gray;
        vertical-align: middle;
        padding: 5px;

        .has-nested-col,
        .head-nested-level-1,
        .head-nested-level-2 {
          position: absolute;
          top: 0;
          left: 0;
          height: 4px;
          background-color: #444;
          z-index: 2;
          width: 100%;
        }

        .has-nested-col.head-nested-level-1,
        .head-nested-level-2 {
          background-color: #444;
          height: 8px;
          z-index: 3;
        }

        &.has-nested-col {
          min-width: 40px !important;
          max-width: 40px !important;
          width: 40px !important;

          .tbl-head-title-row {
            flex-flow: column-reverse;
            gap: 4px;

            span {
              display: inline-flex;
              align-items: center;

            }
          }
        }

        &#col-action {
          text-align: center;
          width: 125px;

          .tbl-head-clm .tbl-head-title-row .tbl-head-title-text {
            width: 100%;
            text-align: center;
          }
        }
      }

      .tbl-head-clm {
        display: flex;
        flex-flow: column;
        align-items: center;

        div {
          width: 100%;
          margin: 0;

          &.MuiInputBase-root {
            padding-right: 0;
            min-width: 100px;

            .MuiButtonBase-root {
              margin-left: -18px;
              width: 28px;
            }
          }
        }

        .tbl-head-title-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 3px;

          .btn {
            padding: 0;
            height: 24px;
            width: 24px;

            .material-icons {
              line-height: 24px;
            }
          }

          .tbl-head-title-text {
            line-height: 25px;
            white-space: nowrap;
          }
        }

        .MuiIconButton-root {
          height: 25px;
          width: 25px;
        }
      }
    }

    tbody {
      td {
        padding: 1px 5px;
        vertical-align: top;
        // min-width: 70px;
        border: 1px solid $medium_gray;

        .trans-label {
          font-size: 0.65em;
          color: $medium3_gray;
          font-weight: 500;
          background: $light_gray; // #f3f3f3;
          padding: 1px 3px;
          margin: 0 3px 2px 0;
          display: inline-block;
        }

        .table-linkable-cell {
          text-decoration: underline;
          cursor: pointer;
        }

        .image-container {
          width: 60px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          cursor: pointer;
          border: 2px solid #c1c1c1;

          /* Optional: For better visibility */
          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            /* or 'contain' depending on the requirement */
          }
        }
      }
    }
  }

  .table-tool-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .tool-section {
      background: $light_gray;
      border: 1px solid $medium_gray;
      border-bottom: 0;
      border-radius: 8px 8px 0 0;

      .material-icons {
        font-size: 21px;
      }
    }

    .action-button-section {
      display: flex;
      gap: 15px;
      margin: 10px 8px;
    }
  }

  .table-date-range-filter-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: $light_gray;
    border: 1px solid $medium_gray;
    border-bottom: 0;

    .date-range-filter-section {
      display: flex;
      gap: 15px;
      margin: 10px 8px;
      min-width: 800px;

      .date-inputs {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .table-footer-row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
    padding: 5px;
    background: $light_gray;
    border: 1px solid $medium_gray;
    border-top-width: 0;

    div {
      align-items: center;

      input {
        text-align: center;
      }
    }
  }


  .MuiInputBase-root {
    height: 28px;
  }

  .MuiInputBase-input {
    height: 15px;
    padding: 3px 20px 3px 7px;
    width: 100%;
  }

  label {
    &.MuiFormLabel-root {
      transform: translate(7px, 4px) scale(1);
      font-size: $fs_body;
    }

    &.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.7);
    }
  }

  .col-action .MuiIconButton-root {
    height: 30px;
    width: 30px;
    margin: 0 2px;
    background: $white_color !important;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid currentcolor;
    }

    .material-icons {
      font-size: 21px;
    }
  }
}

/* utilities */
.gap-2 {
  gap: 0.7rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

/* Property VAlue with Unit as suffix */
.value-box {
  display: flex;
  // align-items: center;
  // gap: 5px;

  .unit-box {
    width: 75px;
    font-weight: 500;
    background: $light_gray;
    height: 44px;
    font-size: 0.8rem;
    margin-top: 6px;
    border: 1px solid $medium2_gray;
    justify-content: CENTER;
    align-items: center;
    border-left: none;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    line-height: 44px;
  }
}

.drawer-box {
  .MuiPaper-root {
    width: 100%;
    max-width: 900px;
    min-width: 300px;
    overflow: hidden;

    .loader.overlay::after {
      display: none;
    }
  }
}


.translations-names {
  margin: 0;
  padding: 0;
  list-style: none;
}

.error-text-list {
  display: block;
  margin-top: -20px;
  margin-bottom: 20px;
}

.animation-fadeIn {
  animation: fadeInAnimation ease 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// type tag UI
.type-tag-box {
  border: 1px solid $medium2_gray;
  padding: 15px 5px !important;
  background-color: $white_color;
  display: flex;

  &:not(:first-child) {
    border-top: 0;
  }

  >div {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .tags-name-clm {
    border-right: 1px solid $medium2_gray;
    flex: 0 0 260px;
    max-width: 260px;
    margin: auto 0;

    h5 {
      text-transform: capitalize;
    }
  }

  .tags-name-clm-without-width {
    border-right: 1px solid $medium2_gray;
    flex: 0 0;
    margin: auto 0;

    h5 {
      text-transform: capitalize;
    }
  }

  .checkbox-options {
    display: flex;
    align-items: center;
    gap: 14px;

    >* {
      display: flex;
      align-items: center;
    }
  }
}

.emptyTypeData {
  min-height: calc(100vh - 160px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Page Route Style
.page_route {

  .page_route_header {
    // &:first-child {
    //   hr {
    //     display: none;
    //   }
    // }

    .collpase_btn {
      .material-icons {
        margin: 0 !important;
      }

      display: flex;
      align-items: center;
      gap: 0.5rem;

      .row {
        width: 98%;
      }
    }

    .index_label {
      font-weight: 400;
      margin-left: 5px;
      min-width: 2.15rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.bg_effect {
      -webkit-animation: bgEffectAnimation .4s infinite;
      /* Safari 4+ */
      -moz-animation: bgEffectAnimation .4s infinite;
      /* Fx 5+ */
      -o-animation: bgEffectAnimation .4s infinite;
      /* Opera 12+ */
      animation: bgEffectAnimation .4s infinite;
      /* IE 10+, Fx 29+ */
    }

    @keyframes bgEffectAnimation {

      0%,
      49% {
        background-color: transparent;
      }

      50%,
      100% {
        background-color: $bg_gray;
      }
    }
  }

  .property_wrapper {
    border: 1px solid $bg_gray;
    padding-top: 16px;

    .property_item {
      padding-bottom: 10px;
      margin-bottom: 10px;

      &:not(:last-of-type) {
        border-bottom: 1px solid $bg_gray;
      }
    }
  }
}

.fields_wrapper {
  border: 1px solid $bg_gray;
  padding: 16px;
  margin-bottom: 16px;
}

// END Page Route Style

.codeWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .generateCodeBtn {
    font-weight: 400;
    color: $secondary_color;
    text-decoration: none;
    margin-bottom: 0px;
    cursor: pointer;
  }
}

.drag_and_drop_block {
  padding: 16px;
  padding-left: 0;

  .drag_over {
    background: $white_color;
  }
}

.form-row {
  &.row-gap-sm {
    row-gap: 20px;
  }

  &.row-gap-xs {
    row-gap: 10px;
  }
}

.MuiInputAdornment-positionStart {
  p {
    background: $light_gray;
    color: $medium3_gray;
    display: inline-block;
    font-size: 0.65em;
    font-weight: 500;
    margin: 0 3px 0 0;
    padding: 1px 3px;
  }
}

.language-btn {
  outline: none !important;
  box-shadow: none;
  display: inline-flex;
  margin-top: 18px;
  font-weight: 400;
  color: $secondary_color;
  text-decoration: none;
  gap: 3px;
  align-items: center;
  font-size: 0.85em;

  .material-icons {
    font-size: 22px;
  }
}

.is-demensial {
  margin-top: 36px;
  // margin-left: 5px !important;
}

.mui-number-field {

  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.form-tool-section {
  position: absolute;
  right: 0;
  display: flex;
  z-index: 7;
  top: 0px;
  background: $light_gray;
  border: 1px solid $bg_gray;
  transition: 0.3s ease all;
  border-width: 0 0 1px 1px;
  // border-radius: 0 0 0 8px;
  flex-flow: row-reverse;

  button {
    outline: none !important;
  }

  &.active {
    background: $medium_gray;
  }

  >.floating-btton-list {
    border-right: 1px solid $medium_gray;
    background: $light_gray;
    // border-radius: 0 0 0 8px;
    flex-flow: row-reverse;
    display: flex;
  }

  .material-icons {
    font-size: 21px;
  }
}

.navigation_accordion {
  // background-color: #fefefe;
  margin-top: 2rem;

  .nav_aco_item {
    // margin-bottom: 10px;

    .nav_aco_item_content {
      padding: 5px 10px;
      margin-bottom: 10px;

      &>div {
        padding: 5px 0;
      }

      ul {
        list-style-type: disc;
        padding-left: 20px;
      }
    }

    .nav_aco_item_child_wrapper {
      margin: 10px;
      border: 1px solid $medium_gray;
    }

    .nav_aco_item_title {
      padding: 0px;
      border-bottom: 1px solid $medium_gray;
      border-top: 1px solid $medium_gray;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 30px;

      &:last-of-type {
        border-bottom: none;
      }
    }

    .nav_aco_item {
      &:first-of-type {
        .nav_aco_item_title {
          border-top: none;

        }
      }
    }

  }
}

.select-wrapper {
  display: flex;

  .MuiAutocomplete-root {
    &:not([name="variants"], [name="stockTagIds"], [name="inventoryTagIds"]) {
      width: 80% !important;
    }
  }

  .icon {
    width: 75px;
    font-weight: 500;
    background: $light_gray;
    height: 44px;
    font-size: 0.8rem;
    margin-top: 6px;
    border: 1px solid $medium2_gray;
    border-left: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .MuiSelect-icon {
    display: none;
  }

  .MuiAutocomplete-endAdornment {
    .MuiAutocomplete-popupIndicator {
      display: none;
    }
  }
}

.media-field {
  .MuiAutocomplete-root {
    width: 100%;
  }
}

//google button
.g-button {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  margin-bottom: 0 !important;

  .g-logo {
    width: 21px;
    height: 21px;
  }

  .g-text {
    font-size: 16px;
    margin: 0;
    text-align: center;
  }
}
.select-table-dropdown-popup {
  height: auto !important;
  margin-right: auto;
  margin-left: auto !important;
  max-width: 750px;
  margin-top: 15vh !important;
  .select-table-container {
    min-height: auto !important;
  }
}

.select-table-popup {
  padding: 0;
  background: $white_color;
  height: 96vh;
  width: 96vw;
  margin-left: 2vw;
  margin-top: 2vh;
  position: relative;
  outline: none;

  h2 {
    margin-bottom: 0;
  }

  .btn-close {
    position: absolute;
    right: 0;
    top: -4;
    z-index: 9;
  }

  .select-table-container {
    display: flex;
    position: relative;
    flex-flow: column;
    min-height: 430px;
    overflow: hidden;
    height: 100%;
    justify-content: space-between;

    >div {
      padding: 15px;
    }

    .select-table-header {
      position: sticky;
      top: 0;
      width: 100%;
      left: 0;
      z-index: 8;
      background: $white_color;
      margin: 0;
      padding-bottom: 0;
      display: flex;
      flex-flow: column;
      gap: 15px;
    }

    .alert {
      margin: 15px;
    }

    .advanced-table {
      padding-top: 0;
      min-height: calc(100% - 160px);

      .table-responsive {
        min-height: calc(100% - 75px);
      }
    }

    .selected-items {
      overflow: auto;
      white-space: nowrap;
    }

    .select-table-footer {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      position: sticky;
      bottom: 0;
      width: 100%;
      left: 0;
      z-index: 8;
      padding: 8px 0;
      margin-top: 0;
      background: $white_color;
      border-top: 1px solid $medium_gray;

      .selected-tags-clm {
        max-width: calc(100% - 300px);
      }

      .select-table-footer-btn-clm {
        display: flex;
        gap: 8px;
        justify-content: flex-end;

        .MuiButton-root {
          margin: 0;
          min-width: 125px;
        }
      }


    }

  }
}

.app-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  background: $medium_gray;
  font-size: 12px;
  padding: 2px 6px;

  .app-version {
    display: flex;
    gap: 10px;
  }
}

// tab menu css

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
  flex-wrap: nowrap;
  overflow-y: hidden;
  white-space: nowrap;

  .nav-link {
    background-color: transparent;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 8px 20px;
  }

  .nav-link:focus-visible {
    outline: none;
  }

  .nav-link:focus {
    outline: none;
  }
}

// .navigation-prefix {
  .url-input-prefix {
    max-width: 50%;

    .MuiTypography-root {
      overflow: hidden;
      text-align: right;
      text-overflow: ellipsis;
      // font-size: 1.3em;
    }

  }
// }

.navigation-hierarchy-wrap {
  .row {
    flex-grow: 1;
  }

  .radio-box {
    background-color: #eeeeee;
  }
}

.navigation-block {
  padding: 1rem;
  border: 2px solid $gray_color2;

  .row {
    &:first-child {
      hr {
        border-bottom-width: inherit;
      }
    }
  }
}

.tag-chip {
  min-width: 50px;
}

.tag-chip-active {
  min-width: 50px;
  border: 1px solid #0e61ff !important;
  color: #fff !important;
  background-color: #0e61ff !important;
}

.min-height-200 {
  min-height: 200px;
}

.order-details-status {
  border: 0.5px solid #eee;
  padding: 1px 6px;
}

// advance table row status ...
.status_yellow {
  background: #fdfd96;
  color: #000;
}

.status_green {
  background: #9ab973;
  color: #000;
}


.status_white {
  background: #ffffff;
  color: #000;
}

.status_red {
  background: #ff5349;
  color: #000;
}

.status_dark_gray {
  background: #a9a9a9;
  color: #000;
}

.status_light_gray {
  background: #eeeeee;
  color: #000;
}

.status_sky_blue {
  background: #9bddff;
  color: #000;
}

.text-editor {
  .ql-editor {
    min-height: 150px;
  }
}

.colour-code-wrapper {
  display: flex;
  gap: 5px;
}

.colour-code-select {
  .MuiSelect-select {
    display: flex;
    gap: 5px;
    align-items: center !important;
  }
}

.colour-code-icon {
  width: 15px;
  height: 15px;
  display: inline-block;
  // commented as the gap looks larger, uncomment it if you want it 
  // margin-right: 8px;
  border: 0.5px solid #6c757dcf;
}

.auto-hight-modal {
  padding: 15px;
  background: #ffffff;
  height: auto;
  width: 76vw;
  margin-left: 2vw;
  margin-top: 2vh;
  position: relative;
  outline: none;
  max-height: 90vh;
  overflow: auto;
}

.common-modal {
  padding: 0;
  background: #ffffff;
  height: auto;
  width: 76vw;
  margin-left: 2vw;
  margin-top: 2vh;
  position: relative;
  outline: none;
  max-height: 90vh;
  overflow: auto;

  .common-modal-header {
    position: sticky;
    top: 0;
    background: $white_color;
    z-index: 3;
    padding: 15px 20px;
    border-bottom: 1px solid $medium_gray;
  }

  .common-modal-body {
    padding: 15px 20px;
  }

  .common-modal-footer {
    position: sticky;
    background: $white_color;
    bottom: 0;
    margin-bottom: 0;
    z-index: 3;
    padding: 15px 20px;
    border-top: 1px solid $medium_gray;
  }
}

.button-container-space-btwn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 4px;
}

.MuiAutocomplete-popper {
  .MuiAutocomplete-listbox {
    .MuiAutocomplete-option {
      justify-content: space-between;
    }
  }

  div {
    background: $light_gray !important;
    font-weight: bold !important;
    border-bottom: 1px solid $white_color;
    text-transform: capitalize;

    color: $black_color;

    & div {
      border-top: 1px solid $white_color;
    }

    li {
      background: $white_color !important;
      font-weight: normal !important;

      &:hover {
        background: $light_gray !important;
      }
    }
  }
}

.image-dropdown {
  max-width: 100px;
  gap: 40px;
  width: 30px;
}

.selected-image {
  &.image-dropdown {
    max-width: 100px;
    gap: 40px;
    width: 100%;
  }
}

.removable-block {
  position: relative;

  .btn-remove {
    position: absolute;
    right: -15px;
    top: -15px;
    z-index: 9;
  }
}

.price-calculator-result {
  .section-Heading-label {
    h3 {
      margin: 0;
      background: #d3d0d0;
      padding: 4px 20px;
    }

  }

  .section-sub-heading {
    background: #e7e7e7;
    padding: 4px 20px;
    font-weight: 600;
  }

  .metal-cost-list {
    border: 1px solid #d3d0d0;
    padding: 0;
    width: 100%;
    min-width: 320px;
    max-width: 600px;

    h5 {
      text-align: center;
      padding: 3px;
      background: #e7e7e7;
    }
    h6 {
      text-align: center;
      padding: 3px;
      background: #e7e7e7;
    }

    ul {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      li {
        padding: 2px;
      }
    }

    .metal-cost-footer {
      text-align: center;
      padding: 3px;
      background: #e7e7e7;
      font-weight: 600;
    }
  }

  .total-table {
    max-width: 500px;
    width: 100%;
    float: right;
    border: 1px solid #d3d0d0;
  }

  .final-price-Section {
    background: #d0e5ff;
    text-align: center;
    padding: 10px;

    ul {
      display: flex;
      width: 100%;
      justify-content: center;

      li {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 2px 0;
      }
    }
  }
  
  .final-price-markup-Section{
  background-color: #ffffff !important;
}
}




.cost-details-table {
  table {
    width: 100%;
    // tr {
    //   min-width: 350px !important;
    //   word-break: break-all;
    // }
  }

  td {
    padding: 2px;
    text-align: right;
  }
}

.bg-gray {
  background: #e7e7e7;
}

.border-bold-top {
  border-top: 2px solid;
}

.features-group-wrapper {
  &:first-child {
    hr {
      display: none;
    }
  }

  .page_route_header {
    &:first-child {
      hr {
        display: none;
      }
    }
  }
}

.modal-table-wrapper {
  .MuiDialog-paper {
    min-width: 50%;
  }
}



.radio-label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1em;
  user-select: none;

  input {
    display: none;

    &:checked~.checkmark {
      background-color: #0e61ff;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;

    &:after {
      left: 9px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      content: "";
      position: absolute;
      display: none;
    }
  }
}


.radio-label input:checked~.checkmark:after {
  display: block;
}

.dimond-details-list div {
  display: flex;
  justify-content: space-between;
}

.border-start {
  border-left: 1px solid;

  &:first-child {
    border: 0;
  }
}

.create-line-item-form {
  overflow-y: scroll;
  height: 75vh;
}

.rbc-calendar {
  width: 100%;
}

.vertical-tabs {
  flex-flow: row;
  display: flex;
  overflow: auto;

  .tab-content {
    flex: 1 1 auto;
    padding: 15px 0;
    border: 0 !important
  }

  .sub-title {
    font-size: 10px;
  }

  .nav-tabs {
    flex-flow: column;
    border-right: 1px solid #dee2e6;
    border-bottom: 0;
    min-width: max-content;

    >.nav-item>.nav-link {
      width: 100%;
      border-radius: 0.25rem 0 0 0.25rem;
      text-align: left;
      display: flex;
      flex-direction: column !important;

      &.active {
        width: 100%;
        border-color: #dee2e6;
        border-right: 0px;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

#panel1-header>.Mui-expanded {
  margin: 12px 0;
}

.schedule-popup {
  background: $white_color;
  height: max-content;
  width: 45vw;
  margin: auto;
  margin-top: 25vh;
  outline: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    display: flex;
    justify-content: space-between;

    h2 {
      margin-bottom: 0;
    }
  }
}

#col-selectAction {
  .tbl-head-clm {
    .tbl-head-title-row {
      justify-content: center;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.user-calendar {
  .rbc-timeslot-group {
    min-height: 70px !important;
  }

  .rbc-event-label {
    max-width: 65px !important;
  }
}

.dimond-details-list {
  min-height: 208px;
}

textarea {
  border: 1px solid #c4c4c4;
  padding: 5px 10px;
  border-radius: 0;
  outline-width: thin !important;
}

.xs-height-table {
  .advanced-table {
    .table-responsive {
      &.card {
        max-height: 200px !important;
        min-height: 170px !important;
      }
    }
  }
}

.crousal-image-continer {
  width: 100%;
  /* Fixed width */
  max-height: 500px;
  /* Fixed height */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    max-width: auto;
    max-height: auto;
    object-fit: cover;
    /* or 'contain' depending on the requirement */
  }
}

.swiper-nav {
  padding: 26px 20px;
  border-radius: 6px;
  background: #ffffffb0;
  color: #000;
}

.swiper {
  .swiper-button-next {
    @extend .swiper-nav;
  }

  .swiper-button-prev {
    @extend .swiper-nav;
  }
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.img-border {
  border: 2px solid #c1c1c1;
}

.page-select-wrapper {
  .MuiFormControl-root {
    flex: 2;
  }

  .view-link {
    flex: 1;
  }
}

.multi-level-drop-wrap {
  display: flex;
  gap: 10px;
  padding: 10px 15px;
  border: none;

  .heading {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .multi-level-child {
    border: 1px solid #d7dbdf;
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    flex: 1;
  }

  .tree-item-box {
    padding: 0 !important;
    margin-top: 10px;
    border: none;

    .tree-item-box {
      margin-top: 5px;
      min-width: 650px;
      max-width: 650px;
    }
  }

  .MuiTreeItem-content {
    margin-bottom: 0;
  }

  .MuiTreeView-root {
    display: flex;
    gap: 20px;
    flex: 1;

    .MuiTreeItem-content {
      background-color: transparent !important;
    }
  }

  .multi-level-drop-box {

    // width: 100%;
    .tree-item-box {
      padding: 10px;
    }
  }

  .drop-link {
    padding-top: 2px;
    margin-left: auto;
    white-space: nowrap;
  }
}

.linked-page-modal {
  .MuiDialog-paper {
    min-width: 950px;
    min-width: 950px;
  }
}

.collection-feature-modal {
  .MuiDialog-paper {
    min-width: 1000px;
    min-width: 1000;
  }
}

.advanced-table {
  table {
    thead {
      .tbl-head-clm {
        div {
          .MuiInputAdornment-root {
            width: 15px !important;
          }
        }
      }
    }
  }
}
.max-height-scroll {
  max-height: 120px;
  scrollbar-width: none;
}